@tailwind components;

.text-small {
    font-size: 0.8125rem;
}

// #region Lucide Icon

.lucide {
    color: var(--icon-1);
    width: 1.125rem;
    height: 1.125rem;
}

.aspect-none {
    aspect-ratio: none !important;
}

// #endregion

// #region Toastify
.Toastify__toast-container:has(> .toastify-position-center-center) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    max-width: 310px;
}

// #endregion

@layer components {

    // #region Title

    .title-1 {
        @apply text-title-1 text-2xl leading-[1.875rem] font-semibold;
    }

    .title-2 {
        @apply text-active text-[1.0625rem] leading-[1.375rem] font-semibold;
    }

    .title-3 {
        @apply text-active text-[1.3125rem] leading-[1.625rem] font-semibold;
    }

    // #endregion

    // #region Icon

    .icon-right-input {
        @apply absolute right-3 top-1/2 -translate-y-1/2 hover:cursor-pointer;
    }

    // #endregion

    // #region Link

    .link-1 {
        @apply inline-block text-link-1 font-medium text-[0.8125rem] leading-4;
    }

    // #endregion

    // #region description

    .desc-1 {
        @apply text-label-1 font-medium text-small leading-3;
    }

    .desc-2 {
        @apply text-text-3 text-[0.8125rem] leading-[1.125rem] font-medium;
    }

    // #endregion

    // #region text button

    .button-1 {
        @apply text-[0.8125rem] leading-[1.125rem] font-normal;
    }

    // #endregion

    // #region detect auto fill

    .detect-autofill {
        input:is(:-webkit-autofill, :autofill)+label {
            @apply top-2 -translate-y-0 scale-75;
        }
    }

    // #endregion

    // #region label

    .label-1 {
        @apply leading-[1.125rem] font-normal;
    }

    // #endregion

    // #region Form group control
    .form-control-group {
        &.form-control-group-invalid {
            .form-control-group-child {
                @apply border-destructive;
            }
        }
    }

    // #endregion

    // #region container
    .container-shop-info-destop {
        @apply bg-white md:px-4 px-0 md:pt-6 pt-0 md:pb-4 pb-0 grid md:grid-cols-2 grid-cols-1 gap-2.5 rounded;
    }

    // #endregion

    // #region Scrollbar
    @for $i from 1 through 10 {
        .scrollbar-width-#{$i} {
            &::-webkit-scrollbar {
                width: #{$i}px;
            }
        }

        .scrollbar-height-#{$i} {
            &::-webkit-scrollbar {
                height: #{$i}px;
            }
        }
    }

    // #endregion
}