.ql-editor {
    h1 {
        font-size: 1.625rem !important;
    }
    
    h2 {
        font-size: 1.21875rem !important; 
    }
    
    h3 {
        font-size: 0.950625rem !important; 
    }
    
    h4 {
        font-size: 0.8125rem !important;
    }
    
    h5 {
        font-size: 0.674375rem !important;
    }
    
    h6 {
        font-size: 0.544375rem !important;
    }

    a{
        color: #007bff;
    }

    a:hover{
        text-decoration: underline;
    }
}

