@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./variables";

@layer base {
  :root {
    @each $property, $color in $base-colors-normal {
      #{$property}: $color;
    }

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  * {
    @apply m-0 p-0 box-border break-words;
  }

  body {
    @apply bg-background text-foreground text-sm;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }

  // #region Scroll
  /* width */
  ::-webkit-scrollbar {
    @apply w-2;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-none rounded-lg;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-active rounded-lg;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-active;
  }

  // #endregion

  // #region hidden arrow input type number

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  // #endregion

  // #region reset auto fill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  // #endregion

  // #region placeholder
  ::placeholder {
    @apply font-normal;
  }

  // #endregion
}

// #region appointment status calender

.appointment-not-confirmed {
  color: #D9001B;
  border-left: 2px solid #D9001B;
  padding-left: 4px;
  font-weight: 500;
  font-size: 11px;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }

  @media screen and (max-width: 920px) {
    border: none;
    padding-left: 0;
  }
}

.appointment-confirmed {
  color: #F59A23;
  border-left: 2px solid #F59A23;
  padding-left: 4px;
  font-weight: 500;
  font-size: 11px;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }

  @media screen and (max-width: 920px) {
    border: none;
    padding-left: 0;
  }
}

.appointment-completed {
  color: #4B7902;
  border-left: 2px solid #4B7902;
  padding-left: 4px;
  font-weight: 500;
  font-size: 11px;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }

  @media screen and (max-width: 920px) {
    border: none;
    padding-left: 0;
  }
}

// #endregion