.rdtPicker {
    padding: 1rem !important;
    border-width: 1px !important;
    min-width: 275px !important;
    max-width: 300px !important;
    border-radius: var(--radius);

    .rdtSwitch {
        font-weight: 600;
        font-size: 0.85rem !important;

        &:hover {
            background-color: var(--accent) !important;
            color: var(--accent-foreground) !important;
        }
    }

    .rdtPrev,
    .rdtNext {
        color: var(--icon-1) !important;
        background-color: inherit !important;
    }

    .rdtYear,
    .rdtMonth {
        font-weight: 400;
        font-size: 0.8125rem !important;
        color: var(--calendar-1) !important;
        padding: 0px !important;
        border-radius: 0.25rem
            /* 4px */
            !important;

        &:hover {
            background-color: var(--accent) !important;
            color: var(--accent-foreground) !important;
        }
    }

    .rdtActive {
        background-color: var(--active) !important;
        color: var(--primary-foreground) !important;

        &:hover,
        &:focus {
            background-color: var(--active) !important;
            color: #FFFF !important;
        }
    }

    .rdtDisabled {
        color: var(--muted-foreground) !important;
        opacity: 0.5;
    }
}