$lg: 1024px;

.toastui-calendar-weekday-event-dot {
    display: none;
}

.toastui-calendar-floating-layer {
    display: none !important;
}

.calendar-dashboard {
    .percentage .tooltip-value::after {
        content: "%";
    }

    .rdtPicker {
        left: 50%;
        transform: translateX(-50%);
    }

    .container {
        padding-left: 0;
        padding-right: 0;
        position: relative !important;

        .toastui-calendar-layout {
            border: 1px solid #F4F4F4;
            border-radius: 4px;

            .toastui-calendar-day-names {
                padding: 0;
                background-color: #fafafa !important;
                height: 46px !important;

                .toastui-calendar-day-name-item {
                    display: flex;
                    align-items: center;
                    height: 46px !important;
                }

                .toastui-calendar-template-monthDayName {
                    font-weight: 500;
                    color: #303030;
                }
            }

            .toastui-calendar-month-daygrid {
                height: calc(100% - 46px) !important;

                .toastui-calendar-month-week-item {
                    .toastui-calendar-weekday {
                        .toastui-calendar-weekday-grid {
                            .toastui-calendar-daygrid-cell {
                                .toastui-calendar-grid-cell-header {
                                    @media screen and (max-width: $lg) {
                                        height: 30px !important;
                                        display: flex !important;
                                        flex-direction: column !important;
                                    }

                                    .toastui-calendar-grid-cell-date {
                                        @media screen and (max-width: $lg) {
                                            height: 15px !important;
                                            width: 16px;
                                        }

                                        .toastui-calendar-weekday-grid-date {
                                            font-weight: 600;

                                            @media screen and (max-width: $lg) {
                                                height: 20px;
                                                line-height: 20px;
                                                width: 20px;
                                                font-size: 12px;
                                            }
                                        }

                                        .toastui-calendar-weekday-grid-date-decorator {
                                            background-color: transparent;
                                            color: #9A6051;
                                            font-weight: 800;
                                            text-decoration: underline;
                                            margin-left: 0;
                                        }
                                    }

                                    .toastui-calendar-grid-cell-more-events {
                                        @media screen and (max-width: $lg) {
                                            height: 15px !important;
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                        }

                        .toastui-calendar-weekday-events {
                            @media screen and (max-width: $lg) {
                                top: 8px !important;
                            }

                            .toastui-calendar-weekday-event-block {
                                display: block;

                                .toastui-calendar-weekday-event {
                                    margin-left: 4px !important;
                                }
                            }
                        }
                    }

                    .toastui-calendar-weekday-event-block {
                        display: none;
                    }
                }


            }



            .toastui-calendar-grid-selection {
                z-index: -1;
            }
        }
    }
}

.recharts-cartesian-axis-tick-value {
    font-size: 10px;
}